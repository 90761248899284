import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { v1 as uuid } from 'uuid';
import { AUTH_TOKEN, LICENSE_ACCEPTED } from '../../constants';

import FullscreenContainer from '../FullscreenContainer/FullscreenContainer';
import SonnenLogo from '../../static/images/logo-sonnen-white-new.svg';
import IllustrationImage from '../../static/images/login-illustration.svg';

import './Login.scss';

/**
 * GraphQL mutation used for logging in
 * Returns an authToken and nickname
 */
const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $username: String!
    $password: String!
    $clientMutationId: String!
  ) {
    login(
      input: {
        clientMutationId: $clientMutationId
        username: $username
        password: $password
      }
    ) {
      authToken
      user {
        id
        username
        firstName
        lastName
        avatar {
          url
        }
      }
    }
  }
`;

interface ILogin {
  authFunction: (user: any) => void;
}

/**
 * Login component that uses a graphql mutation
 */
const Login: React.FunctionComponent<ILogin> = (props: ILogin) => {
  const { authFunction } = props;
  const history = useHistory();
  const clientMutationId = uuid();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [formError, setFormError] = React.useState(false);

  const handleError = (error: any) => {
    console.log('LOGIN ERROR');
    console.log(error);
    if (
      error.message === 'GraphQL error: incorrect_password' ||
      error.message === 'GraphQL error: invalid_username'
    ) {
      setFormError(true);
    }
  };

  const [login] = useMutation(LOGIN_MUTATION, {
    onCompleted({ login }) {
      const { authToken, user } = login;
      localStorage.setItem(AUTH_TOKEN, authToken);
      localStorage.setItem(LICENSE_ACCEPTED, 'false');
      setFormError(false);
      authFunction(user);
      history.push('/');
    },
    onError: handleError,
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (localStorage.getItem(AUTH_TOKEN)) {
      localStorage.removeItem(AUTH_TOKEN);
    }

    login({ variables: { username, password, clientMutationId } });
  };

  return (
    <FullscreenContainer>
      <div className="login">
        <div className="login__container">
          <div className="login__header">
            <img src={SonnenLogo} alt="Sonnen brandguide" />
          </div>
          <div className="login__content">
            <div className="login__welcome">
              <div className="login__welcome__illustration">
                <img src={IllustrationImage} alt="Welcome" />
              </div>
              <p>
                Welcome to the <span>brand portal of sonnen</span>
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="fieldGroup">
                <label htmlFor="username">E-Mail</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  placeholder="E-Mail"
                  autoComplete="username"
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="fieldGroup">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  placeholder="Password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="login__buttons">
                <button type="submit">Login</button>
                <a href="https://brand-api.sonnen.de/wp-login.php?sonnen_saml_login">
                  Use Microsoft Account to Log In
                </a>
              </div>
            </form>
            {formError && (
              <div className="login__error">
                <p>Wrong Username and/or Password</p>
              </div>
            )}
          </div>
        </div>
        <footer className="login__footer">
          <div className="login__footer__container">
            <div className="login__footer__copyright">
              <p>© sonnen GmbH - All rights reserved</p>
            </div>
            <div className="login__footer__links">
              <a
                href="https://account.sonnen.de/de/terms-of-use"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              <a
                href="https://sonnen.de/impressum"
                target="_blank"
                rel="noopener noreferrer"
              >
                Imprint
              </a>
              <a
                href="https://sonnen.de/datenschutz"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </div>
          </div>
        </footer>
      </div>
    </FullscreenContainer>
  );
};

export default Login;
